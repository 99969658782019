/* You can add global styles to this file, and also import other style files */
iframe {
    border: none;
  }
  .report-container {
    height: 100%;
    width: 100%;
  }
  

.fast{
    -webkit-animation-duration: 0.3s;
    animation-duration: 0.3s;
    -webkit-animation-fill-mode:both;
    animation-fill-mode:both
}

#themecolors .selector, .cursor {
    cursor: pointer;
}


#google-btn {
    align-items: center;
    display: flex;
    justify-content: center;
    text-align: center;
}

.img-avatar {
    height: 200px;
    width: 200px;
}

.w75 {
    width: 75px;
}

.w100 {
    width: 100px;
}

.w140 {
    width: 140px;
}

.w200 {
    width: 200px;
}

.avatar {
    border-radius: 100%;
    width: 30px;
}

.oculto {
    display: none;
}

.fondo-modal-imagen {
    background-color: rgba(0, 0, 0, 0.4);
    left: 0px;
    height: 100%;
    position: fixed;
    top: 0px;
    width: 100%;
    z-index: 9999;
}